import { AuthProvider, Refine, usePermissions, useSetLocale } from "@pankod/refine-core";
import { notificationProvider, Layout, AntdLayout, Menu } from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import "@pankod/refine-antd/dist/styles.min.css";
import { useTranslation } from "react-i18next";
import { Header } from "./components/layout";
import simpleRestDataProvider from "./dataProvider";
import { CustomSider } from "./components";
import { SzallitasList } from "./pages/szallitas"
import hu_HU from "antd/lib/locale/hu_HU";
import dayjs from "dayjs";
import "dayjs/locale/hu";
import moment from "moment";
import "moment/locale/hu";
import { useEffect } from "react";
import { ConfigProvider, LoginPage, ErrorComponent } from "@pankod/refine-antd";
import { SzallitasShow } from "./pages/szallitas/show";
import { SzallitasCreate } from "./pages/szallitas/create"
import { TermekCreate } from "./pages/termekTorzs";
import { Login } from "./pages/login"
import "@pankod/refine-antd/dist/styles.min.css";
import { DashboardPage } from "./pages/dashboard"
import { TermekList } from "./pages/termekTorzs/list";
import {model, adapter} from "./accessControl"
import { newEnforcer } from "casbin";
import { authProvider } from "./authProvider";
import { accessControlProvider } from "./accessControlProvider";
import { Exportalas } from "./pages/exportalas/list";
import { ExclamationCircleOutlined, ExportOutlined } from "@ant-design/icons";
import { SzallitasEdit } from "./pages/szallitas/edit";
import { Ellenorzes } from "./pages/ellenorzes/list";

function App() {
  const { t, i18n } = useTranslation();
  const changeLanguage = useSetLocale();
  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };
  //const API_URL = "http://127.0.0.1:8000/api";
  const API_URL = "/api";
  const dataProvider = simpleRestDataProvider(API_URL);
  

  const { Link } = routerProvider;
  i18nProvider.changeLocale("hu");
  const locale = i18nProvider.getLocale();
  useEffect(() => {
    if (locale === "hu") {
      moment.locale("hu");
    } else {
      dayjs.locale("en");
      moment.locale("en");
    }
  }, [locale]);
  
  
    return (

    <ConfigProvider locale={locale === "hu" ? hu_HU : undefined}>
      <Refine
        authProvider={authProvider}
        routerProvider={routerProvider}
        notificationProvider={notificationProvider}
        DashboardPage={DashboardPage}
        Title={() => (
          <Link to="/" style={{ float: "left", marginRight: "80px" }}>
            <img
              src="/setting_settings_wrench_cogwheel_icon_193940.ico"
              alt="SQS"
              style={{ width: "70px" }}
            />
          </Link>
        )}
        dataProvider={dataProvider}
        resources={[
          {
            name: "bizonylat_fejs",
            list: SzallitasList,
            show: SzallitasShow,
            create: SzallitasCreate,
            edit: SzallitasEdit,
            options: {
              label: "Szállítólevelek"
            }
          },
          {
            name: "termeks",
            list: TermekList,
            options: {
              label: "Termékek"
            }
          },
          {
            name: "bizonylat_fejs",
            parentName: "Export",
            options: {
              label: "Exportálás"
            },
            icon: <ExportOutlined />,
            list: Exportalas
          },
          {
            name: "bizonylat_fejs",
            parentName: "Ellenorzes",
            options: {
              label: "Jóváhagyás"
            },
            icon: <ExclamationCircleOutlined />,
            list: Ellenorzes
          }
        ]}
        Layout={Layout}
        i18nProvider={i18nProvider}
        LoginPage={Login}
      />
    </ConfigProvider>
  );
}

export default App;
