import {
  CrudFilter,
  CrudFilters,
  HttpError,
  useExport,
  usePermissions,
  useTranslate,
  useUpdateMany,
} from "@pankod/refine-core";
import {
  List,
  TextField,
  BooleanField,
  TagField,
  DateField,
  Table,
  useTable,
  EditButton,
  ShowButton,
  useDrawerForm,
  Drawer,
  Edit,
  Form,
  Input,
  ExportButton,
  Create,
  DeleteButton,
  useSelect,
  Select,
  Button,
  CreateButton,
  Row,
  Col,
  Icons,
  FormProps,
  getDefaultFilter,
  Card,
  Icon,
  Checkbox,
} from "@pankod/refine-antd";
import { Tabs } from 'antd';
import { ConfigProvider } from "antd";
import "moment/locale/hu";
import huHU from "antd/lib/locale/hu_HU";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { DatePicker, Space } from "antd";
import moment from "moment";
import {
  IBizonylatFej,
  IBizonylatTetelek,
  IBizonylatFilter,
} from "../../interfaces";
import { IVevok } from "../../interfaces/vevok";
import React, { useEffect, useMemo, useState } from "react";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export const Exportalas: React.FC = () => {
  const {
    tableProps: tabePropsBiz,
    sorter,
    searchFormProps,
    filters,
    tableQueryResult
  } = useTable<IBizonylatFej, HttpError, IBizonylatFilter>({
    syncWithLocation: true,
    permanentSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    initialFilter: [
      {
        field: "engedelyezve",
        operator: "eq",
        value: "1",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const expfilters: CrudFilters = [
        {
          field: "paginate",
          operator: "eq",
          value: false,
        },        
      ];

      const { projectId, datum, id, exportalva } = params;


      filters.push({
        field: "projectId",
        operator: "eq",
        value: projectId,
      });


      filters.push(
        {
          field: "datum",
          operator: "gte",
          value: datum ? datum[0].startOf("day").toISOString() : undefined,
        },
        {
          field: "datum",
          operator: "lte",
          value: datum ? datum[1].endOf("day").toISOString() : undefined,
        },
        {
          field: "exportalva",
          operator: "eq",
          value: exportalva,
        },
      );
      projectId && filters.push({
        field: "bizonylat.projectId",
        operator: "eq",
        value: projectId,
      });
      datum &&
        expfilters.push(
          {
            field: "bizonylat.datum",
            operator: "gte",
            value: datum ? datum[0].startOf("day").toISOString() : undefined,
          },
          {
            field: "bizonylat.datum",
            operator: "lte",
            value: datum ? datum[1].endOf("day").toISOString() : undefined,
          },
          {
            field: "bizonylat.exportalva",
            operator: "eq",
            value: exportalva,
          },
        );

      setExportFilter(expfilters);
      setSearchExportFilter(exportFilters);

      return filters;
    },
  });

  const { t } = useTranslation();

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const { data: permissionsData } = usePermissions();
  const [isActive, setIsActive] = useState(false);

  const [exportFilters, setExportFilter] = React.useState<CrudFilter[]>([
    {
      field: "paginate",
      operator: "eq",
      value: false,
    },
  ]);

  const [searchExportFilters, setSearchExportFilter] = React.useState<CrudFilter[]>([
    {
      field: "paginate",
      operator: "eq",
      value: false,
    },
  ]);

  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
    const filters: CrudFilters = [
      {
        field: "paginate",
        operator: "eq",
        value: false,
      },
    ];
    if (selectedRowKeys.length > 0) {
      selectedRowKeys.map((selected) => {
        filters.push({
          field: "bizonylat.id[]",
          operator: "eq",
          value: `${selected}`,
        });
      });

      //alert(JSON.stringify(filters))
      setExportFilter(filters);
      //alert(JSON.stringify(`FILTEREK: ${exportFilters}`))
    } else {
      //alert(JSON.stringify(searchExportFilters))
      setExportFilter(searchExportFilters);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,

  };
  const hasSelected = selectedRowKeys.length > 0;

  const {
    drawerProps: drawerPropsBiz,
    formProps: formPropsBiz,
    show: showBiz,
    saveButtonProps: saveButtonPropsBiz,
    deleteButtonProps: deleteButtonPropsBiz,
    id: idBiz,
  } = useDrawerForm<IBizonylatFej>({
    action: "edit",
  });

  function onChange(date: any, dateString: any) {
    console.log(date, dateString);
  }

  const { mutate, isLoading: updateManyIsLoading } = useUpdateMany<IBizonylatFej>();


  const { triggerExport, isLoading } = useExport<IBizonylatTetelek>({
    resourceName: "bizonylat_teteleks",
    filters: exportFilters,
    mapData: (item) => {
      return {
        MASQ: item.bizonylat?.projectId
          ? `MASQ${item.bizonylat?.projectId}`
          : "",
        Dátum: dayjs(item.bizonylat?.datum).format("YYYY. M. D"),
        Mennyiség: item.mennyisege,
        ME: item.termek?.mennyEgyseg,
        Lieferplan: item.termek?.azonosito3,
        Bizonylatszám: item.bizonylat?.bizonylatSzam?.trim(),
        //tetelAzonosito: item.id,
        Termékazonosító: item.bizonylat?.projectId == 15915  ? `${item.termek?.azonosito1} Szegmens` : item.termek?.azonosito1,
        Megnevezés: 
        `${item.termek?.azonosito1 ?? ""} ${item.bizonylat?.projectId == 15915  ? "Szegmens" : ""}, SAP Nr. ${item.termek?.azonosito2 ?? ""}, ${item.termek?.elsoIndex != null ? "ECV Index " + `${item.termek?.elsoIndex}, ` : ""} ${item.termek?.masodikIndex != null ? "EDP Index " + `${item.termek?.masodikIndex}, ` : ""} Lieferplan Nr. ${item.termek?.azonosito3 ?? ""}, Lieferschein Nr. ${item.bizonylat?.bizonylatSzam?.trim()}, Dátum: ${dayjs(item.bizonylat?.datum).format("YYYY. MM. DD")}`.trim(),
        /* termekAzonosito: `${item.termek?.azonosito1 ?? ""}/${
          item.termek?.azonosito2 ?? ""
        }/${item.termek?.azonosito3 ?? ""}/${item.termek?.elsoIndex ?? ""}/${
          item.termek?.masodikIndex ?? ""
        }`.trim(),*/
        Szállító: item.bizonylat?.szallitoNeve,
        Szállító_Címe: item.bizonylat?.szallitoCime,
        Szállító_Adószáma: item.bizonylat?.szallitoAdoszama,
        Vevő: item.bizonylat?.vevoNeve,
        Vevő_Címe: item.bizonylat?.vevoCime,
        Vevő_Adószama: item.bizonylat?.vevoAdoszama,
        Vevő_EuAdószáma: item.bizonylat?.vevoEuAdoszama,
        Szállító_EuAdószáma: item.bizonylat?.szallitoEuAdoszama,

        Járatszám: item.bizonylat?.jaratSzam,
      };
    },
    exportOptions: {
      fieldSeparator: ";",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      title: "SQS szállítólevelek",
    },
  });

  const updateSelectedItems = () => {
    mutate(
      {
        resource: "bizonylat_fejs",
        ids: selectedRowKeys.map(String),
        values: {
          exportalva: true,
        },
      },
      {
        onSuccess: () => {
          setSelectedRowKeys([]);
        },
      },
    );
  };
  const Filter: React.FC<{ formProps: FormProps; filters: CrudFilters }> = (
    props
  ) => {
    dayjs.extend(customParseFormat);
    const { formProps, filters } = props;
    const { RangePicker } = DatePicker;
    const dateFormat = 'YYYY/MM/DD';
    const datum = useMemo(() => {
      const start = getDefaultFilter("datum", filters, "gte");
      const end = getDefaultFilter("datum", filters, "lte");
  
      const startFrom = dayjs(start);
      const endAt = dayjs(end);
  
      if (start && end) {
        return [startFrom, endAt];
      }
      return undefined;
    }, [filters]);
    const t = useTranslate();
    return (
      <Form
        layout="vertical"
        initialValues={{
          q: getDefaultFilter("projectId", filters),
          datum,
        }}
        {...formProps}
        onFinish={(values) => {
          
          if (formProps?.onFinish) {
            formProps.onFinish(values);
          }

          setIsFiltered(true);
        }}
      >
        <Row gutter={[10, 10]} align="bottom">
          <Col xl={12}>
            <Form.Item
              label={"MASQ"}
              name="projectId"
            >
              <Input
                allowClear
                placeholder={"MASQ szerint"}
                prefix={<Icons.SearchOutlined />}
              />
            </Form.Item>
          </Col>
  
          <Col xl={12}>
            <Form.Item
              label={"Kiszállítás dátuma"}
              name="datum"
            >
              <RangePicker /*defaultValue={[dayjs(), dayjs()]}*/ style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xl={12}>
            <Form.Item
              label="Szűrés exportált szállítólevelekre"
              name="exportalva"
              valuePropName="checked"
              initialValue={false}            
  
            >
              <Checkbox>Exportálva</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 40]} align="bottom">
          <Col xs={24} xl={24} md={8}>
            <Form.Item>
              <Button style={{ width: "100%" }} htmlType="submit" type="primary">
                Szűrés
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };
  const TabPane = Tabs.TabPane;
  const location = useLocation()
  const [isFiltered, setIsFiltered] = useState(false); // Új állapot a szűréshez

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xl={32} lg={24} xs={24}>
          <Card bordered={false} title={<h2>Szállítólevelek exportálása számlázáshoz</h2>}>
            <Filter formProps={searchFormProps} filters={filters || []} />
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xl={32} xs={24}>
          <List
            canCreate={permissionsData?.includes("admin")}
            title="Szállítólevelek"
            headerProps={{
              extra: [
                <ExportButton
                //onClick={() => { triggerExport();;}}
                onClick={ () => {

                  triggerExport().then(data => {
                    updateSelectedItems()
                  }
                  );
                }} 
                loading={isLoading} 
                disabled={!isFiltered}
                />,
              ],
            }}
          >
            <Table {...tabePropsBiz} rowSelection={rowSelection} rowKey="id" pagination={{
              ...tabePropsBiz.pagination ,showSizeChanger: true,
              showTotal: (total, range) => `Találatok: ${tableQueryResult.data?.total} (${range[0]}-${range[1]})`,
            }}>

              <Table.Column
                title="Szállítólevél száma"
                dataIndex={"bizonylatSzam"}
              />

              <Table.Column<IBizonylatFej>
                dataIndex="datum"
                title="Dátum"
                render={(value) => (
                  <DateField format="YYYY MMM D,  h:mm" value={value} />
                )}
              />

              <Table.Column
                dataIndex="engedelyezve"
                title="Jóváhagyás"
                render={(value) => (
                  value === true ? <TextField style={{ color: "green" }} value={"Engedélyezve"} /> : <TextField style={{ color: "red" }} value={"Nincs engedélyezve"} />
                )}
              />
              <Table.Column
                dataIndex="exportalva"
                title="Exportálva"
                render={(value) => (
                  <BooleanField value={value} />
                )}
              />
            </Table>
          </List>
        </Col>
      </Row>
    </>
  );
};



